import './index.scss'

const About = () => {
  return (
    <section className="about" id="about">
      <div className="container">
        <div className="about__content">
          <div className="about__content-left">
            <div className="about__circle about__circle--small">
              2019 р.
            </div>
            <div className="about__circle">
              {new Date().getFullYear()} р.
            </div>
          </div>
          <div>
            <h3 className="title">Про нас</h3>
            <p className="about__text">
              З 2019 року команда Seek UAV виготовляє та вдосконалює доступні тепловізійні системи для БПЛА. З початком повномасштабного вторгнення ми сфокусували свою діяльність на забезпеченні потреб українських захисників. За 5 років ми пройшли шлях від ручної збірки до організованого серійного виробництва, налагодивши випуск якісних та надійних тепловізійних систем.
            </p>
            <p className="about__text">
              Сьогодні наші пристрої працюють на всій лінії фронту, допомагаючи бійцям успішно вражати цілі та вести ефективну аеророзвідку у нічний час.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About