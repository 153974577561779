import { useState } from 'react'
import './index.scss'
import Select from './Select'
import Input from './Input'
import Textarea from './Textarea'

const Form = ({ type = 'contact', order, onSuccess, onError }) => {
  const orderForm = type === 'order'
  const contactForm = type === 'contact'
  const messengers = [
    { text: 'Telegram' },
    { text: 'Viber' },
    { text: 'WhatsApp' },
    { text: 'Signal' },
    { text: 'Краще зателефонуйте' },
  ]
  const validators = {
    name: ['required'],
    phone: ['required', 'phone'],
    city: orderForm ? ['required'] : [],
    postNumber: orderForm ? ['required'] : [],
    message: contactForm ? ['required'] : [],
  }
  const [errors, setErrors] = useState({})
  const [formData, setFormData] = useState({})
  const [loading, setLoading] = useState(false)

  const validate = () => {
    const nextErrors = {}
    for (const key in validators) {
      const val = validators[key]
      val.forEach((rule) => {
        if (nextErrors[key]) return
        if (rule === 'required') {
          if (!formData[key]) {
            nextErrors[key] = 'Поле обов’язкове'
          }
        } else if (rule === 'phone') {
          const inputVal = formData[key]
          const valid = /^\+380\d{9}$/i.test(inputVal)
          if (!valid) {
            nextErrors[key] = 'Номер телефону має невірний формат'
          }
        }
      })
    }
    setErrors(nextErrors)
    return Object.keys(nextErrors).length === 0
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (loading) {
      return
    }
    const valid = validate()
    if (valid) {
      setLoading(true)
      const scriptUrl = 'https://script.google.com/macros/s/AKfycbyPLEuke9CLyBzgTgcjPR6bWQx06IxE_dqlep15NPpMiQ4XBDV_NG7iG_Hx5eoLMbFSZg/exec'
      const data = new FormData()
      for (const key in formData) {
        data.append(key, formData[key])
      }

      if (orderForm) {
        data.append('sheetName', 'Замовлення')
        data.append('product', order?.product)
        data.append('specification', order?.specification)
      } else if (contactForm) {
        data.append('sheetName', 'Повідомлення')
      }
      fetch(scriptUrl, {
        method: 'POST',
        body: data
      })
        .then((res) => onSuccess(res))
        .catch((e) => onError(e))
        .finally(() => setLoading(false))
    }
  }

  const handleInputChange = (key, val) => {
    setErrors({ ...errors, [key]: '' })
    setFormData({ ...formData, [key]: val })
  }

  return (
    <form
      className={`form ${contactForm ? 'dark' : ''}`}
      onSubmit={handleSubmit}
    >
      <Input
        placeholder="Ім’я"
        onChange={(e) => handleInputChange('name', e.target.value)}
        error={errors.name}
      />
      <div className="form__block">
        <Input
          placeholder="Номер телефону"
          type="tel"
          onChange={(e) => handleInputChange('phone', e.target.value)}
          error={errors.phone}
        />
        <Select
          items={messengers}
          label="Оберіть месенджер"
          onChange={(e) => handleInputChange('messenger', e.target.value)}
          error={errors.messenger}
        />
      </div>
      {orderForm && (
        <div className="form__block">
          <Input
            placeholder="Місто"
            onChange={(e) => handleInputChange('city', e.target.value)}
            error={errors.city}
          />
          <Input
            placeholder="Відділенні Нової Пошти"
            onChange={(e) => handleInputChange('postNumber', e.target.value)}
            error={errors.postNumber}
          />
        </div>
      )}
      <Textarea
        placeholder={contactForm ? 'Повідомлення' : 'Коментар'}
        onChange={(e) => handleInputChange('message', e.target.value)}
        error={errors.message}
      />
      {order && (
        <div className="form__order-block">
          <div>
            ({order?.count}) товар на суму:
          </div>
          <div>
            {Math.round((order?.price || 0) * order?.count)} грн
          </div>
        </div>
      )}
      <button
        className="button-main button-main--full-width"
        type="submit"
        disabled={loading}
      >
        {loading ? (
          <svg className="button-main__loader" xmlns="http://www.w3.org/2000/svg"
               viewBox="21.904761904761905 21.904761904761905 43.80952380952381 43.80952380952381">
            <circle fill="transparent" cx="43.80952380952381" cy="43.80952380952381" r="20"
                    strokeWidth="3.8095238095238093" strokeDasharray="100" stroke="#fff" />
          </svg>
        ) : (
          <span>
            {orderForm ? 'Замовити' : 'Відправити'}
          </span>
        )}
      </button>
    </form>
  )
}

export default Form