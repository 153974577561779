import {
  useState
} from 'react'
import './index.scss'
import productItems from '../../products/productsItems'
import ProductCount from '../product-count'
import OrderButton from '../order-button'
import Description from '../description'

const ProductOne = ({ productId }) => {
  const productItem = productItems.filter(i => i?.id === productId)[0]
  const [count, setCount] = useState(1)

  return (
    <div className="product-info">
      <h1 className="title">
        {productItem.title}
      </h1>
      <ProductCount
        price={productItem?.price}
        count={count}
        setCount={setCount}
      />
      <OrderButton order={{ count, price: productItem?.price, productItem }} />
      <Description>
        <a
          href="https://docs.google.com/document/d/1unV_bg2hx24CvdNWIW9469374FRxLhWxiwjChiTfN6o/edit?usp=drive_link"
          target="_blank"
        >
          Технічні характеристики комплекту Seek UAV
        </a>
        <p>
          Тепловізійні комплекти для кастомних та FPV дронів побудовані на основі плати Seek UAV DM V4 та USB-тепловізору разом з комплектом кріплення на дрон. Плата конвертує відео у сигнал, який одразу можна підключити до аналогової системи відеопередачі безпілотника.
        </p>
        <p>
          Комплект може містити один з трьох тепловізорів на вибір, які підходять для виконання різних задач. Установка комплекту на борт БПЛА відбувається так само, як звичайної FPV-камери. Затримка конвертації відео на платі становить всього 20-30 мс, а загальна затримка відеопотоку становить 100-200 мс, залежно від моделі тепловізору.
        </p>
      </Description>
    </div>
  )
}

export default ProductOne