import {
  useState
} from 'react'
import './index.scss'
import productItems from '../../products/productsItems'
import ProductCount from '../product-count'
import OrderButton from '../order-button'
import Description from '../description'

const ProductThree = ({ productId }) => {
  const productItem = productItems.filter(i => i?.id === productId)[0]
  const [count, setCount] = useState(1)

  return (
    <div className="product-info">
      <h1 className="title">
        {productItem.title}
      </h1>
      <ProductCount
        price={productItem?.price}
        count={count}
        setCount={setCount}
      />
      <OrderButton order={{ count, price: productItem?.price, productItem }} />
      <Description>
        <a
          href="https://docs.google.com/document/d/1DV76sqIc287j1zs62C2j-eQENGvlNcHfd_vqiJ48J8A/edit?usp=drive_link"
          target="_blank"
        >
          Технічні характеристики Seek UAV DM VTX
        </a>
        <a
          href="https://docs.google.com/document/d/1vQHy1eHNwftafGBwzTELSIwPM9fqKMekFcIxK7v5v3A/edit?usp=drive_link"
          target="_blank"
        >
          Список тепловізорів, що підтримуються Seek UAV
        </a>
        <p>
          Плата-конвертер Seek UAV DM VTX є спрощеною версією плати DM V4 та призначена для виводу аналогового CVBS відео з поширених та доступних USB-тепловізорів. Вона створена для роботи напряму з VTX, та не підходить для роботи з OSD-чіпами. Установка плати на борт БПЛА відбувається так само, як звичайної FPV-камери.
        </p>
        <p>
          Наразі плати Seek UAV підтримують більше 30 моделей тепловізорів з матрицями 384х288, 320х240 та 256х192, які підходять для FPV камікадзе, скидів та розвідки. Затримка конвертації відео на платі становить всього 20-30 мс, а загальна затримка потоку становить 100-200 мс, залежно від моделі тепловізору. Плата працює з усіма видами аналогових VTX
        </p>
      </Description>
    </div>
  )
}

export default ProductThree