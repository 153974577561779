import {
  useState,
  useEffect
} from 'react'
import './index.scss'
import SocialButtons from '../social-buttons'
import logo from '../../img/logo.svg'
import close from './img/close.svg'
import MenuNav from './MenuNav'
import { HashLink as Link } from 'react-router-hash-link'

const Menu = ({ mdDevice }) => {
  const tel = '380967303063'
  const contactFormId = '#contact-form'
  const [menuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    if (!menuOpen) {
      document.body.style.overflowY = 'auto'
    } else {
      document.body.style.overflowY = 'hidden'
      window.scrollTo(0, 0)
    }
  }, [menuOpen])

  const handleMenuButtonClick = () => {
    setMenuOpen(!menuOpen)
  }

  const onLinkClick = () => {
    setMenuOpen(false)
  }

  return (
    <div className="menu">
      <Link to="/">
        <img
          className="menu__logo"
          alt="Logo"
          src={logo}
        />
      </Link>
      {!mdDevice ? (
        <>
          <a href={`tel:${tel}`} className="menu__tel">
            +{tel}
          </a>
          <MenuNav onLinkClick={onLinkClick} />
          <Link to={contactFormId} className="button-outlined button-outlined--small">
            Написати нам
          </Link>
        </>
      ) : (
        <>
          <button
            className={!menuOpen ? 'button-main menu__menu-button' : 'button-outlined menu__menu-button--icon'}
            onClick={handleMenuButtonClick}
          >
            {menuOpen ? (
              <img src={close} alt="Close" />
            ) : (
              <span>Меню</span>
            )}
          </button>
          {menuOpen && (
            <div className="menu__content-mobile">
              <div>
                <MenuNav
                  onLinkClick={onLinkClick}
                  mobile
                />
                <SocialButtons />
              </div>
              <div>
                <Link
                  to={contactFormId}
                  className="button-main button-main--full-width"
                  onClick={onLinkClick}
                >
                  Написати нам
                </Link>
                <a href={`tel:${tel}`} className="menu__tel menu__tel--mobile">
                  +{tel}
                </a>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default Menu