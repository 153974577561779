import {
  useState
} from 'react'
import './index.scss'
import productItems from '../../products/productsItems'
import ProductCount from '../product-count'
import SpecificationSelect from '../specification-select'
import OrderButton from '../order-button'
import Description from '../description'

const ProductFour = ({ productId }) => {
  const productItem = productItems.filter(i => i?.id === productId)[0]
  const [count, setCount] = useState(1)
  const [selectedProduct, setSelectedProduct] = useState({})

  return (
    <div className="product-info">
      <h1 className="title">
        {productItem.title}
      </h1>
      <ProductCount
        price={selectedProduct?.price}
        count={count}
        setCount={setCount}
      />
      <SpecificationSelect
        label="Оберіть комплектацію"
        items={[
          { text: 'Без тепловізору', price: 15000 },
          { text: 'Victor 328B', price: 24000 },
          { text: 'XH09', price: 30000 }
        ]}
        onChange={setSelectedProduct}
        selectedProduct={selectedProduct}
      />
      <OrderButton
        order={{ count, price: selectedProduct?.price, productItem, selectedProduct }}
        disabled={!Object.keys(selectedProduct).length}
      />
      <Description>
        <a
          href="https://docs.google.com/document/d/1CIBYDXq_i9o3BxQd50xfFdntb_jTTW35TZc9iUioWYg/edit?usp=drive_link"
          target="_blank"
        >
          Технічні характеристики Seek UAV Mini
        </a>
        <p>
          Підвісний тепловізійний комплекс Seek UAV Mini призначений для установки на звичайні дрони моделей DJI Mavic, AUTEL та подібні. Він дає змогу виконувати розвідувальні місії та місії коригування вогню у темний час доби. Час польоту дрону з комплексом на борту зменшується всього на 2-5 хвилин, в залежності від моделі.
        </p>
        <div>
          <p>Комплекс Seek UAV Mini має:</p>
          <ul>
            <li>Можливість комплектації тепловізором з різними характеристиками.</li>
            <li>Мінімальну вагу всього 175 грамів та власну систему живлення.</li>
            <li>Передачу відео на поширеній частоті 5.8Ghz з опцією кодування сигналу.</li>
            <li>Швидке кріплення на дрон всього за 20 секунд, з-за допомогою липучки.</li>
          </ul>
        </div>
        <div>
          <p>Комплектація:</p>
          <ul>
            <li>комплекс Seek UAV Mini;</li>
            <li>3 батареї;</li>
            <li>зарядний пристрій;</li>
            <li>екран 5.8Ghz для прийому картинки;</li>
          </ul>
        </div>
      </Description>
    </div>
  )
}

export default ProductFour