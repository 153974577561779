import './index.scss'
import info from './info.svg'
import Select from '../../form/Select'

const SpecificationSelect = ({ selectedProduct, label, items, onChange }) => {
  const handleChange = (e) => {
    const val = e.target.value
    const objValue = items.filter(i => i?.text === val)[0]
    if (objValue && typeof onChange === 'function') {
      onChange(objValue)
    }
  }

  return (
    <div className="specification-select">
      {!selectedProduct?.price && (
        <div className="specification-select__label">
          <img src={info} alt="Info" />
          <span>
             Оберіть специфікацію товару
          </span>
        </div>
      )}
      <Select
        onChange={handleChange}
        items={items}
        label={label}
      />
    </div>
  )
}

export default SpecificationSelect